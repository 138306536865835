<template>
  <v-img :src="imageSource" width="100%" height="100%">
    <div style="width:100%;height:100%;">
      <div class="mt-4 pa-6" style="opacity:.9;margin:auto;max-width:500px">
        <div style="font-size:3em;line-height:2em">Page Not Found</div>
        <div style="font-size:1.5em;min-height:100px">The page you were looking for could not be found.</div>
        <div>
          <v-btn class="mr-8 mb-2" @click="login()">
            <v-icon left small>fas fa-lock</v-icon>
            Go to Portal Dashboard
          </v-btn>
          <v-btn class="mb-2" href="https://www.covenant.edu">
            <img src="/img/shield.png" height="16px" class="mr-2" />
            Covenant Website
          </v-btn>
        </div>
      </div>
    </div>
  </v-img>
</template>
<script>
export default {
  setup (props, { root }) {
    const imageSource = require('@/assets/carter-foggy.jpg')

    function login () {
      if (root.$store.state.loggedIn) {
        root.$router.replace('/')
      } else {
        const basePath = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH.replace('/socket.io', '')
        window.location = basePath + '/saml'
      }
    }

    return {
      imageSource,
      login
    }
  }
}
</script>
